import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Spinner, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./BiddType.css";
import Editbidding from "./Editbidding";

const BiddingTypes = () => {
  const [biddingTypes, setBiddingTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editShow, setEditShow] = useState(false);
  const [editid, setEditid] = useState(null);
  const accessToken = localStorage.getItem("access_token");
  const [data, setData] = useState([])

  const fetchBiddingTypes = async () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      setError("Access token not found.");
      setLoading(false);
      return;
    }
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "ngrok-skip-browser-warning": "69420",
    };
    try {
      await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/bidding-types`,
        { headers }
      )
      .then((response) => {       
          setBiddingTypes(response.data.data);       
      })
    } catch (error) {
      setError("An error occurred while fetching bidding types.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBiddingTypes();
  }, []);


  const handleEditBlog = (id) => {
    setEditid(id);
    setEditShow(true);
    // console.log(`Edit blog with ID: ${id}`);
  };

  // Fetch bidding data when editid changes (only when it is set)
  useEffect(() => {
    if (editid) {
      fetchBidding();
    }
  }, [editid]);

  const fetchBidding = () => {
    if (!editid) return; // Prevent API call if no ID is set
    axios.get(`${process.env.REACT_APP_API_URL}/admin/bidding-types/edit/${editid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "ngrok-skip-browser-warning": "69420",
      },
    })
      .then(response => {
        console.log(response.data.data, "bidding");
        setData(response.data.data)
      })
      .catch(error => {
        console.error("Error fetching bidding data:", error);
      });
  };

  if (loading)
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <p className="mt-3">Loading...</p>
      </div>
    );
  if (error)
    return (
      <div className="text-center mt-5">
        <Alert variant="danger">{error}</Alert>
      </div>
    );

  return (
    <>
      <div className="container pt-3">
        <h2>Bidding Types</h2>
        {biddingTypes.length === 0 ? (
          <div className="text-center mt-5">
            <Alert variant="info">No data found</Alert>
          </div>
        ) : (
          <Table striped bordered hover className="bidding-table mt-5 mb-5">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {biddingTypes.map((biddingType) => (
                <tr key={biddingType?.hash_id}>
                  <td>{biddingType?.name}</td>
                  <td className="text-wrap">{biddingType?.description}</td>
                  <td>{biddingType?.is_active === 1 ? "Enable" : "Disable"}</td>
                  <td
                    onClick={() => handleEditBlog(biddingType?.hash_id)}
                    style={{ cursor: "pointer" }}
                  >
                    Edit
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
      <Editbidding
        open={editShow}
        onClose={() => setEditShow(false)}
        data={data}
        id={editid}
        fetchBidding={fetchBiddingTypes}
      />
    </>
  );
};

export default BiddingTypes;
