import React, { useEffect, useState } from "react";
import "./AboutHibid.css";
import { Helmet } from "react-helmet";
import axios from "axios";

const AboutHibid = () => {
  window.scroll(0, 0);
  const [info, setinfo] = useState();
const url = window.location.href;

  const fetchinfo = async () => {
   await axios.get(`${process.env.REACT_APP_API_URL}/info/about-us`)
    .then((response) => {
      setinfo(response.data.data)
    })
    // console.log(response.data.data, "info")
  }

  useEffect(() => {
    fetchinfo()
  }, [])

  return (
    <>
    <Helmet>
      <title>{`${info?.meta_title || info?.name}`}</title>
        <meta
          name="description"
          content={`${info?.meta_description || info?.name}`}
        />
        <meta
          property="og:title"
          content={`${info?.meta_title || info?.name}`}
        />
        <meta
          property="og:description"
          content={`${info?.meta_description || info?.name}`}
        />
        <meta
          property="og:url"
          content={url}
        />
        <link
          rel="canonical"
          href={url}
        />
      </Helmet>
    <div className="container abouthibibis">
      <div className="row ">
      <p dangerouslySetInnerHTML={{ __html: info?.description || "" }} />        
      </div>
    </div>
    </>
  );
};
export default AboutHibid;
