import React, { useEffect, useState } from "react";
import {
  faAngleDown,
  faAngleUp,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { RingLoader } from "react-spinners";
import { Link } from "react-router-dom";

const CategorySelector = ({ handleCategoryChange }) => {
  const [showCategoryList, setShowCategoryList] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [mainCategoryExpand, setMainCategoryExpand] = useState(null);
  const [expandedSubCategory, setExpandedSubCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const fetchCategories = async () => {
    setLoadingCategories(true);
    try {
     await axios.get(
        `${process.env.REACT_APP_API_URL}/categories`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        }
      )
      .then((response) => {
        setCategoryList(response.data.data);
        // console.log(response.data.data,"categories")
      })
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setLoadingCategories(false);
    }
  };

  useEffect(() => {
    if (showCategoryList) {
      fetchCategories();
    }
  }, [showCategoryList]);

  const handleMainCategoryExpand = (hash_id) => {
    const newCategory = hash_id !== mainCategoryExpand ? hash_id : null;
    setMainCategoryExpand(newCategory);
    setExpandedSubCategory([]);
    setSelectedCategory(newCategory);
    handleCategoryChange(newCategory);
  };

  const handleSubcategoryExpand = (hash_id) => {
    const index = expandedSubCategory.indexOf(hash_id);
    if (index === -1) {
      setExpandedSubCategory([...expandedSubCategory, hash_id]);
    } else {
      setExpandedSubCategory(
        expandedSubCategory.filter((id) => id !== hash_id)
      );
    }
    setSelectedCategory(hash_id);
    handleCategoryChange(hash_id);
  };

  const handleCategoryListToggle = () => {
    setShowCategoryList(!showCategoryList);
  };

  const renderCategoryItems = () => {
    return (
      categoryList?.length > 0 &&
      categoryList.map((category) => (
        <ul key={category.hash_id} className="p-0 m-0">
          {/* <Link to={`/lots?category=${category.name}`}> */}
          <li
            className="sidebar-item sub-category category-border"
            onClick={() => handleMainCategoryExpand(category.hash_id)}>
            <FontAwesomeIcon
              icon={
                mainCategoryExpand === category.hash_id
                  ? faAngleDown
                  : faAngleRight
              }
              className="toggle-icon quick-search"
            />
            <p className="px-2 my-0">{category?.name} <span className="font-semibold">({category.lots_count || 0}) </span></p>
          </li>
          {/* </Link> */}
          {mainCategoryExpand === category.hash_id && (
            <>
              {category.descendants.map(renderSubCategoryItems)}
            </>
          )}
        </ul>
        // </Link>
      ))
    );
  };

  const renderSubCategoryItems = (subCategory) => {
    return (
      <ul key={subCategory.hash_id} className="p-0">
        <li
          className="sidebar-item sub-category category-border pl-3"
          onClick={() => handleSubcategoryExpand(subCategory.hash_id)}>
          <FontAwesomeIcon
            icon={
              expandedSubCategory.includes(subCategory.hash_id)
                ? faAngleDown
                : faAngleRight
            }
            className="toggle-icon quick-search "
          />
          <p className="px-2 my-0">{subCategory?.name} <span className="font-semibold">({subCategory.lots_count || 0})</span></p>
        </li>
        {expandedSubCategory.includes(subCategory.hash_id) && (
          <>
            {subCategory.descendants.map(renderSubCategoryItems)}
          </>
        )}
      </ul>
    );
  };
  return (
    <>
      <div
        className="sidebar-item d-flex auction-states-drop font-semibold sidebar-item-category"
        onClick={handleCategoryListToggle}>
        CATEGORY
        <div className="mx-2">
          <FontAwesomeIcon
            icon={showCategoryList ? faAngleUp : faAngleDown}
            className="toggle-icon quick-search"
          />
        </div>
      </div>
      {showCategoryList && (
        <div className="sidebar-sub-item">
          {loadingCategories ? (
            <div className="d-flex justify-content-center align-items-center">
              <RingLoader
                size={60}
                color={"#123abc"}
                loading={loadingCategories}
              />
            </div>
          ) : (
            <div className="form-group ">{renderCategoryItems()}</div>
          )}
        </div>
      )}
    </>
  );
};

export default CategorySelector;
