import React, { useEffect, useState } from "react";
import "./Editinfo.css";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";

function Editinfo() {
  const [userInfo, setUserInfo] = useState(null);

  const [countryTypes, setCountryTypes] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [countrydata, setCountrydata] = useState(null)

  const [stateTypes, setStateTypes] = useState([]);
  const [statedata, setStatedata] = useState(null);
  const [selectedStateId, setSelectedStateId] = useState("");

  const [cityTypes, setCityTypes] = useState([]);
  const [citydata, setCitydata] = useState(null);
  const [selectedCityId, setSelectedCityId] = useState("");
  const [isSubmitting, setIssubmitting] = useState(false)

  const hash_id = localStorage.getItem("hash_id");
  const token = localStorage.getItem("access_token");
  // console.log(token)

  /*---------------Country fetch-----------------*/
  useEffect(() => {
    if (countrydata) {
      fetch(`${process.env.REACT_APP_API_URL}/countries`)
        .then((response) => response.json())
        .then((data) => {
          if (Array.isArray(data.data)) {
            setCountryTypes(data.data);
            const initialCountry = data.data.find(
              (type) => type.name === countrydata.name
            );
            if (initialCountry) {
              setSelectedCountryId(initialCountry.hash_id);
            }
          } else {
            console.error("Expected an array but received:", data);
          }
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [countrydata]);

  const handleCountryChange = (event) => {
    //  console.log(event.target.value, "event country")
    const selectedId = event.target.value;
    setSelectedCountryId(selectedId);
    const selectedCountry = countryTypes.find(
      (type) => type.hash_id === selectedId
    );
    if (selectedCountry) {
    }
    formik.setFieldValue("country", selectedId);
  };


  /* ----------State fetch-----------*/
  const fetchStates = async (selectedCountryId) => {
    fetch(`${process.env.REACT_APP_API_URL}/states/${selectedCountryId}`)
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data.data)) {
          setStateTypes(data.data);
          const initialBiddingType = data.data.find(type => type.name === statedata?.name);
          if (initialBiddingType) {
            setSelectedStateId(initialBiddingType.hash_id);
          }
        } else {
          console.error('Expected an array but received:', data);
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }

  const handleStateChange = (event) => {
    //  console.log(event.target.value, "event state")
    const selectedId = event.target.value;
    setSelectedStateId(selectedId);
    const selectedType = stateTypes.find(type => type.hash_id === selectedId);
    if (selectedType) {
    }
    formik.setFieldValue("state", selectedId);
  };

  useEffect(() => {
    if (selectedCountryId) {
      fetchStates(selectedCountryId);
    }
  }, [selectedCountryId]);


  /*-----------------City--------------*/
  const fetchCity = async (selectedCityId) => {
    fetch(`${process.env.REACT_APP_API_URL}/city/${selectedCityId}`)
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data.data)) {
          setCityTypes(data.data);
          const initialBiddingType = data.data.find(type => type.name === citydata?.name);
          if (initialBiddingType) {
            setSelectedCityId(initialBiddingType.hash_id);
          }
        } else {
          console.error('Expected an array but received:', data);
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }

  const handleCityChange = (event) => {
    //  console.log(event.target.value, "event city")
    const selectedId = event.target.value;
    setSelectedCityId(selectedId);
    const selectedType = cityTypes.find(type => type.hash_id === selectedId);
    if (selectedType) {
    }
    formik.setFieldValue("city", selectedId);
  };

  useEffect(() => {
    if (selectedStateId) {
      fetchCity(selectedStateId);
    }
  }, [selectedStateId]);



  const fetchUserInfo = async () => {
    try {
      await axios.get(
        `${process.env.REACT_APP_API_URL}/buyer/users/${hash_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      }
      )
      .then((response) => {
        const data = response.data;
        
        const formattedData = {
          ...data,
          address: data.detail?.address || "",
          // fax: data.detail?.fax || "",
          city: data.city.hash_id || "",
          country: data.country?.hash_id || "",
          state: data.state?.hash_id || "",
          postal_code: data.detail?.postal_code || "",
          phone_1: data.detail?.phone1 || "",
          phone_2: data.detail?.phone2 || "",
          roles: data.roles[0]?.id,
          hide_username: data.settings?.hide_username || false,
          allow_magnifying_glass: data.settings?.allow_magnifying_glass || false,
          send_email_notifications:
          data.settings?.send_email_notifications || false,
          newsletter: data.settings?.newsletter || false,
        };
        
        setCountrydata(data.country);
        setStatedata(data.state);
        setCitydata(data.city);
        setUserInfo(formattedData);
        
      })
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    address: Yup.string().required("Address is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string().min(
      8,
      "Password must be at least 8 characters long"
    ),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  const formik = useFormik({
    initialValues: {
      first_name: userInfo?.first_name || "",
      last_name: userInfo?.last_name || "",
      address: userInfo?.address || "",
      // fax: userInfo?.fax || "",
      country: selectedCountryId || "",
      state: selectedStateId || "",
      city: selectedCityId || "",
      postal_code: userInfo?.postal_code || "",
      phone_1: userInfo?.phone_1 || "",
      phone_2: userInfo?.phone_2 || "",
      username: userInfo?.username || "",
      email: userInfo?.email || "",
      hide_username: userInfo?.hide_username || false,
      allow_magnifying_glass: userInfo?.allow_magnifying_glass || false,
      send_email_notifications: userInfo?.send_email_notifications || false,
      newsletter: userInfo?.send_email_notifications || false,
      role: userInfo?.roles,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      setIssubmitting(true)
      // console.log(values, "values")
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/buyer/users/update/${hash_id}`,
          values,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          Swal.fire("Success", "Form Submitted Successfully!");
          formik.resetForm();
          setIssubmitting(false)
        })
      } catch (error) {
        console.error("Error submitting form:", error.response.data);
        Swal.fire("Error", "Error submitting form", error.response.data);
        setIssubmitting(false)
      }
    },
  });

  useEffect(() => {
    fetchUserInfo();
  }, []);


  return (
    <section className="edit-info mt-5 mb-5">
      <div className="container">
        <div className="row">
          <Form onSubmit={formik.handleSubmit} className="p-4">
            <h3 className="mb-3">Edit Personal Info</h3>
            <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
              <div className="w-50 d-grid">
                <label className="font-semibold">First Name</label>
                <Form.Control
                  type="text"
                  name="first_name"
                  placeholder="first_name"
                  className="w-100"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.first_name}
                  isInvalid={
                    formik.touched.first_name && !!formik.errors.first_name
                  }
                />
              </div>
              <div className="w-50 d-grid">
                <label className="font-semibold">Last Name</label>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.first_name}
                </Form.Control.Feedback>
                <Form.Control
                  type="text"
                  name="last_name"
                  placeholder="last_name"
                  className="w-100"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.last_name}
                  isInvalid={
                    formik.touched.last_name && !!formik.errors.last_name
                  }
                />
              </div>
              <Form.Control.Feedback type="invalid">
                {formik.errors.last_name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
              <div className="w-100 d-grid">
                <label className="font-semibold">Address</label>
                <Form.Control
                  type="text"
                  name="address"
                  placeholder="address"
                  className="w-100"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  isInvalid={formik.touched.address && !!formik.errors.address}
                />
              </div>
              <Form.Control.Feedback type="invalid">
                {formik.errors.address}
              </Form.Control.Feedback>
              {/* <div className="w-50 d-grid">
                <label className="font-semibold">Fax</label>
                <Form.Control
                  type="text"
                  name="fax"
                  placeholder="fax"
                  className="w-100"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fax}
                  isInvalid={formik.touched.fax && !!formik.errors.fax}
                />
              </div> */}
              {/* <Form.Control.Feedback type="invalid">
                {formik.errors.fax}
              </Form.Control.Feedback> */}
            </Form.Group>

            <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
              <div className="w-50 d-grid">
                <label className="font-semibold">Country</label>
                <Form.Select
                  name="country"
                  onChange={handleCountryChange}
                  value={selectedCountryId}
                  isInvalid={formik.touched.country && !!formik.errors.country}
                >
                  {Array.isArray(countryTypes) &&
                    countryTypes.map((type) => (
                      <option key={type.hash_id} value={type.hash_id}>
                        {type.name}
                      </option>
                    ))}
                </Form.Select>
              </div>

              <Form.Control.Feedback type="invalid">
                {formik.errors.country}
              </Form.Control.Feedback>
              <div className="w-50 d-grid">
                <label className="font-semibold">State</label>
                <Form.Select
                  name="state"
                  value={selectedStateId}
                  onChange={handleStateChange}
                >
                  {Array.isArray(stateTypes) &&
                    stateTypes.map((type) => (
                      <option key={type.hash_id} value={type.hash_id}>
                        {type.name}
                      </option>
                    ))}
                </Form.Select>
              </div>
            </Form.Group>

            <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
              <div className="w-50 d-grid">
                <label className="font-semibold">City</label>
                <Form.Select
                  name="state"
                  value={selectedCityId}
                  onChange={handleCityChange}
                >
                  {Array.isArray(cityTypes) &&
                    cityTypes.map((type) => (
                      <option key={type.hash_id} value={type.hash_id}>
                        {type.name}
                      </option>
                    ))}
                </Form.Select>
              </div>
              <Form.Control.Feedback type="invalid">
                {formik.errors.city}
              </Form.Control.Feedback>
              <div className="w-50 d-grid">
                <label className="font-semibold">Postal Code</label>
                <Form.Control
                  type="text"
                  name="postal_code"
                  placeholder="postal_code"
                  className="w-100"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.postal_code}
                  isInvalid={
                    formik.touched.postal_code && !!formik.errors.postal_code
                  }
                />
              </div>
              <Form.Control.Feedback type="invalid">
                {formik.errors.postal_code}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
              <div className="w-50 d-grid">
                <label className="font-semibold">Phone1</label>
                <Form.Control
                  type="text"
                  name="phone_1"
                  placeholder="phone_1"
                  className="w-100"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone_1}
                  isInvalid={formik.touched.phone_1 && !!formik.errors.phone_1}
                />
              </div>
              <Form.Control.Feedback type="invalid">
                {formik.errors.phone_1}
              </Form.Control.Feedback>
              <div className="w-50 d-grid">
                <label className="font-semibold">Phone2</label>
                <Form.Control
                  type="text"
                  name="phone_2"
                  placeholder="phone_2"
                  className="w-100"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone_2}
                  isInvalid={formik.touched.phone_2 && !!formik.errors.phone_2}
                />
              </div>
              <Form.Control.Feedback type="invalid">
                {formik.errors.phone_2}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
              <div className="w-50 d-grid">
                <label className="font-semibold">Username</label>
                <Form.Control
                  type="text"
                  name="username"
                  placeholder="username"
                  className="w-100"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                  isInvalid={formik.touched.username && !!formik.errors.username}
                />
              </div>
              <Form.Control.Feedback type="invalid">
                {formik.errors.username}
              </Form.Control.Feedback>
              <div className="w-50 d-grid">
                <label className="font-semibold">Email</label>
                <Form.Control
                  type="text"
                  name="email"
                  placeholder="email"
                  className="w-100"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  isInvalid={formik.touched.email && !!formik.errors.email}
                />
              </div>
              <Form.Control.Feedback type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formHideUsername">
              <Form.Check
                type="checkbox"
                label="Hide Username"
                name="hide_username"
                onChange={formik.handleChange}
                checked={formik.values.hide_username}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formAllowMagnifyingGlass">
              <Form.Check
                type="checkbox"
                label="Allow Magnifying Glass"
                name="allow_magnifying_glass"
                onChange={formik.handleChange}
                checked={formik.values.allow_magnifying_glass}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formSendEmailNotifications">
              <Form.Check
                type="checkbox"
                label="Send Email Notifications"
                name="send_email_notifications"
                onChange={formik.handleChange}
                checked={formik.values.send_email_notifications}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formNewsletter">
              <Form.Check
                type="checkbox"
                label="Newsletter"
                name="newsletter"
                onChange={formik.handleChange}
                checked={formik.values.newsletter}
              />
            </Form.Group>

            <Button variant="primary" type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : "Submit"}
            </Button>
          </Form>
        </div>
      </div>
    </section>
  );
}

export default Editinfo;
