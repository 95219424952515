import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Modal, Form, Button } from "react-bootstrap";
import Swal from 'sweetalert2';
import "./UserEditModal.css"

function UserEditModal({ show, onHide, user, fetchUsers }) {
  const [countryTypes, setCountryTypes] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState(user?.country?.hash_id);
  const [stateTypes, setStateTypes] = useState([]);
  const [selectedStateId, setSelectedStateId] = useState(user?.state?.hash_id);
  const [cityTypes, setCityTypes] = useState([]);
  const [selectedCityId, setSelectedCityId] = useState(user?.city?.hash_id);
  const [roles, setRoles] = useState([])
  const [isSending, setIsSending] = useState(false);

  const token = localStorage.getItem("access_token");

  useEffect(() => {
    fetchRoles();
  }, []);


  /*---------------Country fetch-----------------*/
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/countries`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.data)) {
          setCountryTypes(data.data);
          const initialCountry = data.data.find(
            (type) => type.name === user?.country?.name
          );
          if (initialCountry) {
            setSelectedCountryId(initialCountry.hash_id);
          }
        } else {
          console.error("Expected an array but received:", data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [user]);

  const handleCountryChange = (event) => {
    console.log(event.target.value, "event country")
    const selectedId = event.target.value;
    setSelectedCountryId(selectedId);
    const selectedCountry = countryTypes.find(
      (type) => type.hash_id === selectedId
    );
    if (selectedCountry) {
    }
    formik.setFieldValue("country_id", selectedId);
  };


  /* ----------State fetch-----------*/
  const fetchStates = async (selectedCountryId) => {
    fetch(`${process.env.REACT_APP_API_URL}/states/${selectedCountryId}`)
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data.data)) {
          setStateTypes(data.data);
          const initialBiddingType = data.data.find(type => type.name === user?.state?.name);
          if (initialBiddingType) {
            setSelectedStateId(initialBiddingType.hash_id);
          }
        } else {
          console.error('Expected an array but received:', data);
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }

  const handleStateChange = (event) => {
    console.log(event.target.value, "event state")
    const selectedId = event.target.value;
    setSelectedStateId(selectedId);
    const selectedType = stateTypes.find(type => type.hash_id === selectedId);
    if (selectedType) {
    }
    formik.setFieldValue("state_id", selectedId);
  };

  useEffect(() => {
    if (selectedCountryId) {
      fetchStates(selectedCountryId);
    }
  }, [selectedCountryId]);


  /*-----------------City--------------*/
  const fetchCity = async (selectedCityId) => {
    fetch(`${process.env.REACT_APP_API_URL}/city/${selectedCityId}`)
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data.data)) {
          setCityTypes(data.data);
          const initialBiddingType = data.data.find(type => type.name === user?.city?.name);
          if (initialBiddingType) {
            setSelectedCityId(initialBiddingType.hash_id);
          }
        } else {
          console.error('Expected an array but received:', data);
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }

  const handleCityChange = (event) => {
    console.log(event.target.value, "event city")
    const selectedId = event.target.value;
    setSelectedCityId(selectedId);
    const selectedType = cityTypes.find(type => type.hash_id === selectedId);
    if (selectedType) {
    }
    formik.setFieldValue("city", selectedId);
  };

  useEffect(() => {
    if (selectedStateId) {
      fetchCity(selectedStateId);
    }
  }, [selectedStateId]);


  const fetchRoles = async () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found in localStorage.");
      return;
    }
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "ngrok-skip-browser-warning": "69420",
    };
    try {
      await axios.get(
        `${process.env.REACT_APP_API_URL}/roles`,
        {
          headers,
        }
      )
      .then((response) => {
        setRoles(response.data.roles || []);
      })
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      username: user?.username || "",
      email: user?.email || "",
      address: user?.detail?.address || "",
      city: user?.city?.hash_id || "",
      postal_code: user?.detail?.postal_code || "",
      phone1: user?.detail?.phone1 || "",
      phone2: user?.detail?.phone2 || "",
      // fax: user?.detail?.fax || "",
      country_id: user?.country?.hash_id || "",
      state_id: user?.state?.hash_id || "",
      hide_username: user?.settings?.hide_username || false,
      allow_magnifying_glass: user?.settings?.allow_magnifying_glass || false,
      send_email_notifications: user?.settings?.send_email_notifications || false,
      newsletter: user?.settings?.newsletter || false,
      role: user?.roles?.[0]?.id || "",
      is_active: user?.is_active || 0,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsSending(true);
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/admin/users/update/${user.hash_id}`,
          values,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          Swal.fire("Form Submitted Successfully!");
          onHide();
          fetchUsers();
        })
      } catch (error) {
        console.error("Error submitting form:", error);
        Swal.fire("Error", "Error submitting form", "error");
      } finally {
        setIsSending(false);
      }
    },
  });


  return (
    <Modal show={show} onHide={onHide} className='edituser'>
      <Modal.Header closeButton>
        <Modal.Title>Edit User</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{maxWidth: "800px"}}>
        <Form onSubmit={formik.handleSubmit}>
        <div className='w-100 d-flex g-3' style={{gap: "10px"}}>
          <Form.Group className="mb-3 w-50">
            <Form.Label>Username</Form.Label>
            <Form.Control
              name="username"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.username}
            />
          </Form.Group>
          <Form.Group className="mb-3 w-50">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name='email'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
          </Form.Group>
        </div>
          <Form.Group className="mb-3">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              name='address'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
            />
          </Form.Group>
          <div className='w-100 d-flex g-3' style={{gap: "10px"}}>
          <Form.Group className="mb-3 w-50">
            <Form.Label>Country</Form.Label>
            <select
              value={selectedCountryId}
              onChange={handleCountryChange}
              name="country"
              id="country"
              className="w-100 border-1 p-2"
            >
              {Array.isArray(countryTypes) &&
                countryTypes.map((type) => (
                  <option key={type.hash_id} value={type.hash_id}>
                    {type.name}
                  </option>
                ))}
            </select>
            </Form.Group>
          <Form.Group className="mb-3 w-50">
            <Form.Label>State</Form.Label>
            <select
              value={selectedStateId}
              onChange={handleStateChange}
              name="state"
              id="state"
              className="w-100 border-1 p-2"
            >
              {Array.isArray(stateTypes) &&
                stateTypes.map((type) => (
                  <option key={type.hash_id} value={type.hash_id}>
                    {type.name}
                  </option>
                ))}
            </select>
          </Form.Group>
          </div>
          <div className='w-100 d-flex g-3' style={{gap: "10px"}}>
          <Form.Group className="mb-3 w-50">
            <Form.Label>City</Form.Label>
            <select
              value={selectedCityId}
              onChange={handleCityChange}
              name="city"
              id="city"
              className="w-100 border-1 p-2"
            >
              {Array.isArray(cityTypes) &&
                cityTypes.map((type) => (
                  <option key={type.hash_id} value={type.hash_id}>
                    {type.name}
                  </option>
                ))}
            </select>
          </Form.Group>
          
          <Form.Group className="mb-3 w-50">
            <Form.Label>Postal Code</Form.Label>
            <Form.Control
              type="text"
              name='postal_code'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.postal_code}
            />
          </Form.Group>
          </div>
          
          <div className='w-100 d-flex ' style={{gap: "10px"}}>
          <Form.Group className="mb-3 w-50">
            <Form.Label>Phone 1</Form.Label>
            <Form.Control
              type="text"
              name='phone1'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone1}
            />
          </Form.Group>
          <Form.Group className="mb-3 w-50">
            <Form.Label>Phone 2</Form.Label>
            <Form.Control
              type="text"
              name='phone2'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone2}
            />
          </Form.Group>
          </div>
          <Form.Group className="mb-3">
            <Form.Label>Role</Form.Label>
            <Form.Select
            name='role'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.role}
            >
              <option value="">Select Role</option>
              {roles.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3 w-50">
            <Form.Check
              type="checkbox"
              name='hide_username'
              label="Hide Username"
              onChange={(e) => {
                const value = e.target.checked ? 1 : 0;
                formik.setFieldValue("hide_username", value);
              }}
              onBlur={formik.handleBlur}
              checked={formik.values.hide_username === 1}
            />
          </Form.Group>          
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              name='allow_magnifying_glass'
              label="Allow Magnifying Glass"
              onChange={(e) => {
                const value = e.target.checked ? 1 : 0;
                formik.setFieldValue("allow_magnifying_glass", value);
              }}
              onBlur={formik.handleBlur}
              checked={formik.values.allow_magnifying_glass === 1}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              name='send_email_notifications'
              label="Send Email Notifications"
              onChange={(e) => {
                const value = e.target.checked ? 1 : 0;
                formik.setFieldValue("send_email_notifications", value);
              }}
              onBlur={formik.handleBlur}
              checked={formik.values.send_email_notifications === 1}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              name='newsletter'
              label="Newsletter"
              onChange={(e) => {
                const value = e.target.checked ? 1 : 0;
                formik.setFieldValue("newsletter", value);
              }}
              onBlur={formik.handleBlur}
              checked={formik.values.newsletter === 1}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              name='is_active'
              label="Active"
              onChange={(e) => {
                const value = e.target.checked ? 1 : 0;
                formik.setFieldValue("is_active", value);
              }}
              onBlur={formik.handleBlur}
              checked={formik.values.is_active === 1}
            />

          </Form.Group>
          <div className="w-full border-t flex items-center justify-end pt-3 gap-2">
            <Button variant="primary" type="submit" disabled={isSending}>
              {isSending ? "Saving..." : "Save"}
            </Button>
            <Button variant="secondary" onClick={onHide}>
              Close
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default UserEditModal
