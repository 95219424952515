import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import "./AuctionDetail.css";
import Magnifier from "react-magnifier";
import { Helmet } from "react-helmet";
import CloseIcon from "@mui/icons-material/Close";
import Signinmodel from "../Siginmodel/Signinmodel";
import CheckIcon from "@mui/icons-material/Check";
import demo from "../../../assets/images/demo.jpg";

const AuctionDetail = () => {
  const { hash_id } = useParams();
  const [auctionData, setAuctionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const url = window.location.href;
  const [open, setOpen] = useState(false);
  const handleClosed = () => setOpen(false);
  const isLoggedIn = !!localStorage.getItem("access_token");
  const [remainingTime, setRemainingTime] = useState("");

  // Fetch auction data
  useEffect(() => {
    const fetchAuctionData = async () => {
      try {
       await axios.get(
          `${process.env.REACT_APP_API_URL}/get/auctions/${hash_id}`
        )
        .then((response) => {
          const { auction } = response.data;
          setAuctionData(auction);
          // console.log(auction, "All lot hash_ids");
        })
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchAuctionData();
  }, [hash_id]);

  useEffect(() => {
    if (new Date(auctionData?.end_date) > new Date()) {
      const interval = setInterval(() => {
        setRemainingTime(calculateRemainingTime(auctionData?.end_date));
      }, 1000);
      // Cleanup interval when auction ends
      return () => clearInterval(interval);
    }
  }, [auctionData?.end_date]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error.message}</div>;
  }

  if (!auctionData) {
    return <div className="no-data">No auction data found</div>;
  }

  const lots = auctionData.lots || [];
  const firstSixLots = lots;

  const handleOpenLoginModal = () => {
    setOpen(true);
  };

  const handleClick = () => {
    const isLoggedIn = !!localStorage.getItem("access_token");
    if (!isLoggedIn) {
      handleOpenLoginModal();
    } else {
    }
  };

  const calculateRemainingTime = (endDate) => {
    const now = new Date();
    const end = new Date(endDate);
    const diff = end - now;

    if (diff > 0) {
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      return `${hours}h ${minutes}m ${seconds}s`;
    }
    return "Closed";
  };

  // Function to calculate days left
  const calculateDaysLeft = (endDate) => {
    const now = new Date();
    const end = new Date(endDate);
    const diff = end - now;
    return Math.floor(diff / (1000 * 60 * 60 * 24));
  };

  return (
    <>
      <Helmet>
        <title>
          {auctionData.meta_title || auctionData.name} - AuctionLive
        </title>
        <meta name="description" content={auctionData.meta_description} />
        <meta property="og:title" content={auctionData.meta_title} />
        <meta
          property="og:description"
          content={auctionData.meta_description}
        />
        <meta property="og:url" content={url} />
        <link rel="canonical" href={url} />
      </Helmet>
      <div className="container pt-3">
        <div className="row border-1 rounded mb-5 p-4">
          <h4 className="text-capitalize">{auctionData?.name}</h4>
          <div className="col-12 col-md-4">
            <img
              src={
                auctionData?.auction_images?.length > 0
                  ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${auctionData?.auction_images[0]?.image_path}`
                  : demo
              }
              alt={auctionData?.name}
              style={{ width: "300px", height: "280px", objectFit: "contain" }}
            ></img>
          </div>
          <div className="col-12 col-md-8">
            <h5 className=" mt-3 text-capitalize">
              <Link
                className="text-decoration-none text-dark"
                to={`/companysearch/${auctionData?.user_details?.id}`}>
                {auctionData?.user_details?.company}
              </Link>
            </h5>
            {auctionData?.city?.name &&
              auctionData?.state?.name &&
              auctionData?.country?.name &&
              auctionData?.zip_code && (
                <p>
                  {auctionData?.city?.name}, {auctionData?.state?.name},{" "}
                  {auctionData?.country?.name}, {auctionData?.zip_code}
                </p>
              )}
            <p>
              <span className="font-semibold">Date:</span>{" "}
              {new Date(auctionData?.start_date).toLocaleDateString()} -{" "}
              {new Date(auctionData?.end_date).toLocaleDateString()}
            </p>
            <p>{auctionData?.description}</p>
            <div className="w-100 d-flex justify-content-between align-items-center gap-3">
              {!isLoggedIn && (
                <button
                  className="btn btn-primary mt-3 w-100"
                  onClick={handleClick}>
                  Register Now
                </button>
              )}
              {auctionData?.bidding_type_id?.name && (
                <button
                  className="btn btn-danger prebidding-open-btn p-2 mt-3 w-100"
                  style={{
                    background: "#dc3545",
                    border: "1px solid",
                    cursor: "unset",
                  }}>
                  {auctionData?.bidding_type_id?.name}
                </button>
              )}
              <button
                className={`btn btn-primary mt-3 w-100 ${
                  new Date() < new Date(auctionData?.end_date)
                    ? "prebidding-open-bt"
                    : ""
                }`}
                style={{
                  cursor: "unset",
                  backgroundColor:
                    new Date() < new Date(auctionData?.end_date)
                      ? ""
                      : "#6C757D",
                }}>
                <span>
                  <i
                    className={`fa-solid ${
                      new Date() < new Date(auctionData?.end_date)
                        ? "fa-wifi"
                        : "fa-ban"
                    }`}
                  ></i>
                </span>
                &nbsp;
                {new Date() < new Date(auctionData?.end_date)
                  ? "Bid is Live"
                  : "Bid is Closed"}
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h5 className="mb-3">Lots ({auctionData?.lots?.length})</h5>
            <div className="lots-row">
              {firstSixLots.map((lot, index) => (
                <div className="lot-item" key={lot.hash_id}>
                  <Link
                    to={`/lots/${lot.hash_id}`}
                    style={{ textDecoration: "none" }}>
                    <div className="lot-title text-black text-capitalize">
                      Lot {lot?.lot_number} | {lot?.title}
                    </div>
                    <Magnifier
                      src={
                        lot?.lot_first_image?.image_path
                          ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${lot?.lot_first_image?.image_path}`
                          : demo
                      }
                      alt={lot?.title}
                      loading="lazy"/>
                    <div className="lot-details">
                      <p className="text-black mb-0">
                        High Bid:{" "}
                        {lot?.highest_bidder?.status === "Winning"
                          ? lot?.highest_bidder?.amount
                          : "0"}
                      </p>

                      {lot?.reserve_price != null &&
                        ((lot?.highest_bidder?.amount || 0) >
                        lot?.reserve_price ? (
                          <p className="reserve-text text-success font-semibold mb-0">
                            <CheckIcon /> Reserve Met
                          </p>
                        ) : (
                          <p className="reserve-text text-danger font-semibold mb-0">
                            <CloseIcon /> Reserve Not Met
                          </p>
                        ))}

                      <span className="font-semibold">
                        {new Date(auctionData?.end_date) > new Date()
                          ? calculateDaysLeft(auctionData?.end_date) > 1
                            ? `${calculateDaysLeft(
                                auctionData?.end_date
                              )} days left`
                            : // Display remaining time when it's the last day
                              `${remainingTime} left`
                          : "Closed"}
                      </span>
                      <p className="text-black">
                        Bids: {lot?.bids?.length || 0}
                      </p>
                      <button className="bid-button">View Detail</button>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Signinmodel open={open} handleClose={handleClosed} />
    </>
  );
};

export default AuctionDetail;
